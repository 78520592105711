.detalhes {
  background-color: white;
  padding: 1rem 0rem;

  @media(max-width: 1087px) {
    padding: 4rem 1rem;
  }

  &__caracteristicas {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }

  &__caracteristica {
    text-align: center;
    margin: 1rem 2rem;
    min-width: 20%;
  }

  &__icon {
    height: 3rem;
    width: 3rem;
    fill: var(--color-evento-1);
  }

  &__icon-wrapper {
    margin-right: 1rem;
  }

  &__caracteristica-titulo {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.8rem;
  }

  &__caracteristica-texto {
    margin-top: 1rem;
  }

  @include query(phone) {
    padding: 4rem 1rem 1rem;
  }
}
