.contador-pagina {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  &__carregando {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .botao-2 {
      background-color: #ef5350;
      margin-left: 1rem;
    }
  }

  &__carregando-info {
    .efeito-simples {
      margin-left: 1rem;
    }
  }

  &__footer {
    align-self: stretch;
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 1.4rem;
  }

  &__link {
    color: #555;
    text-decoration: underline;
  }
}

.contador {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #555;

  &__titulo {
    font-size: 2.4vw;
    text-transform: uppercase;
    color: #282ea6;
  }

  &__logo {
    @include query(desk-med) {
      max-width: 350px;
    }
  }

  &__texto {
    margin-top: 2rem;
    font-size: 3.1vw;
    //text-transform: uppercase;
    text-align: center;
    font-weight: lighter;
    color: #282ea6;
  }

  &__numero-pdvs {
    font-size: 12vw;
    padding: 1rem 2rem;
    line-height: 12vw;
    font-weight: bolder;

    background-image: linear-gradient(180deg, #ffb500, #ff7200);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent !important;

    //border: 2px dashed #b9250a;
    //border-radius: 15px;
  }
}
