.evento {
  background-color: #fff;
  background-attachment: fixed;

  &__texto-participacao {
    font-size: 1.8rem;
    margin: 2.5rem 0;

    &--center {
        margin: 0;
        text-align: center;
        color: #282828;
        display: block;
    }
  }
}
