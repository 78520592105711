.dados-pessoais {
  padding: 2rem 0;

  &__inputs {
    margin: 2rem 0;
  }
  &__input-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    &--multiple {
      .dados-pessoais__input-wrapper {
        margin: 0.5rem;
      }
    }
  }
  &__input-wrapper {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0.5rem;
    flex: 1;
  }
  &__input {
    font-family: inherit;
    font-size: 17px;
    border: none;
    color: inherit;
    padding: 2rem 2rem;
    width: 100%;
    transition: all 0.2s;
    border: 1px solid var(--color-grey-light-3);
    border-radius: var(--border-radius);
    margin: 1rem 0;
    //margin-left: -3.25rem;

    &:focus {
      outline: none;
      //width: 110%;
      //background-color: var(--color-grey-light-3);
    }

    &::-webkit-input-placeholder {
      font-weight: 100;
      color: var(--color-grey-dark-3);
    }

    &--select {
      height: 6rem;
      background-color: #fff;
    }

    &--textarea {
      resize: none;
      height: 15rem;
    }
  }
}

.comprar-form {
  &__investimento {
    text-align: center;
    font-size: 1.8rem;
  }
  &__valor {
    color: var(--color-evento-2);
  }
  &__input-wrapper {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
  }
  &__input {
    font-family: inherit;
    font-size: 19px;
    border: none;
    color: inherit;
    padding: 1.2rem 2rem;
    padding-left: 4rem;
    width: 70%;
    transition: all 0.2s;
    border-radius: var(--border-radius);
    background-color: var(--color-grey-light-3);
    margin: 1rem 0;
    //margin-left: -3.25rem;

    &:focus {
      outline: none;
      width: 80%;
      //background-color: var(--color-grey-light-3);
    }

    &::-webkit-input-placeholder {
      font-weight: 100;
      color: var(--color-grey-dark-1);
    }
  }

  &__submit-wrapper {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }
  &__submit {
    margin: 0 auto;
  }
}
