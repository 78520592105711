.datepicker-wrapper {
  margin: 2rem 0;

  //overwrites de estilos default

  .PresetDateRangePicker_button {
    border: 2px solid var(--color-evento-2);
    color: var(--color-evento-2);
  }
  .PresetDateRangePicker_button__selected {
    color: #fff;
    background: var(--color-evento-2);
  }
  .SingleDatePickerInput {
    background-color: #fff;
  }
  .SingleDatePickerInput__withBorder {
    border: 1px solid #dbdbdb;
  }
  .SingleDatePickerInput__disabled {
    background-color: #f2f2f2;
  }
  .SingleDatePickerInput__showClearDate {
    padding-right: 30px;
  }
  .SingleDatePickerInput_clearDate__default:focus,
  .SingleDatePickerInput_clearDate__default:hover {
    background: #dbdbdb;
    border-radius: 50%;
  }
  .SingleDatePickerInput_clearDate_svg {
    fill: #82888a;
  }
  .SingleDatePickerInput_calendarIcon_svg {
    fill: #82888a;
  }
  .SingleDatePicker_picker__portal {
    background-color: rgba(0, 0, 0, 0.3);
  }
  .DayPickerKeyboardShortcuts_show__bottomRight {
    border-right: 33px solid var(--color-evento-2);
  }
  .DayPickerKeyboardShortcuts_show__bottomRight:hover {
    border-right: 33px solid var(--color-evento-2);
  }
  .DayPickerKeyboardShortcuts_show__topRight {
    border-right: 33px solid var(--color-evento-2);
  }
  .DayPickerKeyboardShortcuts_show__topRight:hover {
    border-right: 33px solid var(--color-evento-2);
  }
  .DayPickerKeyboardShortcuts_show__topLeft {
    border-left: 33px solid var(--color-evento-2);
  }
  .DayPickerKeyboardShortcuts_show__topLeft:hover {
    border-left: 33px solid var(--color-evento-2);
  }
  .CalendarDay__blocked_minimum_nights:active,
  .CalendarDay__blocked_minimum_nights:hover {
    background: #fff;
    color: #cacccd;
  }
  .CalendarDay__highlighted_calendar {
    background: #ffe8bc;
    color: #484848;
  }
  .CalendarDay__highlighted_calendar:active,
  .CalendarDay__highlighted_calendar:hover {
    background: #ffce71;
    color: #484848;
  }
  .CalendarDay__selected_span {
    background: var(--color-evento-1);
    border: 1px solid var(--color-evento-1);
    color: #fff;
  }
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: var(--color-evento-1);
    border: 1px solid var(--color-evento-1);
    color: #fff;
  }
  .CalendarDay__last_in_range {
    border-right: var(--color-evento-2);
  }
  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover {
    background: var(--color-evento-2);
    border: 1px solid var(--color-evento-2);
    color: #fff;
  }
  .CalendarDay__selected_span {
    background: var(--color-evento-1);
    border: 1px solid var(--color-evento-1);
    color: #fff;
  }
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: var(--color-evento-1);
    border: 1px solid var(--color-evento-1);
    color: #fff;
  }
  .CalendarDay__hovered_span:active {
    background: var(--color-evento-1);
    border: 1px solid var(--color-evento-1);
    color: #fff;
  }
  .DateRangePickerInput__withBorder,
  .DateInput,
  .DateInput_input {
    border-radius: var(--border-radius);
  }
  .DateInput_input__focused {
    border-bottom: 2px solid transparent;
  }
  .DateInput_input__focused::placeholder {
    color: var(--color-evento-2);
  }
}
