.descricao {
  display: flex;

  &__titulo {
    margin-right: 6rem;
    max-width: 45rem;
    line-height: 1.2;
  }

  &__texto {
    p {
      margin-bottom: 1rem;
      font-size: 2rem;
      width: 100%;

      @media(max-width: 800px) {
        font-size: 1.6rem;
      }
    }
  }

  @include query(tab-port) {
    flex-direction: column;
  }
}

.descricao-media {
  &__chamada {
    color: var(--color-evento-2);
    font-size: 1.8rem;
    margin: 1rem auto;
  }

  &__com-imagem {
    display: flex;
    justify-content: flex-end;

    @media(max-width: 900px) {
      justify-content: center;
    }

    &__imagem {
      width: auto; 
      height: 12rem;
      margin: 4rem 0 8rem 0;

      @media(max-width: 900px) {
        height: 8rem;
      }

      @media(max-width: 600px) {
        height: 6rem;
      }
    }
  }
  
  &__com-video {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6rem;

    &__video {
      box-shadow: var(--shadow-light);
      margin: 0 auto;
      overflow: hidden;
    }

    &__vimeo-player {
      width: 70%;
      height: 100%;

      @media(max-width: 500px) {
        width: 100%;
      }
  
      div {
        height: 33rem;
  
        @media(max-width: 500px) {
          height: 21rem;
        }
      }
    }
  }

}
