.hospedagens {
  padding: 2rem 0;
  &__subtitulo {
    color: var(--color-grey-dark-3);
  }

  &__datepicker-wrapper {
    margin: 2rem 0;
  }

  &__datepicker-input-wrapper {
    display: flex;
    align-items: center;
    @include query(phone) {
      flex-direction: column;
    }
  }

  &__nao-preciso {
    margin: 2rem 0;
    margin-left: 5rem;
    color: #ff6b00;
    text-decoration: underline;
    background-color: transparent;
    box-shadow: none;
    padding: 1rem;
    text-transform: initial;
    cursor: pointer;
    font-size: 1.8rem;
    &--marcado {
      background-color: #ff6b00;
      text-decoration: none;
      color: #fff;
    }

    @include query(phone) {
      margin: 1rem auto;
    }
  }

  &__quartos-wrapper {
    margin-top: 3rem;
  }

  &__hotel-aviso {
    margin-top: 1rem;
    font-weight: bold;
    color: var(--color-evento-2);
  }
}

.quartos {
  display: flex;
  flex-direction: column;

  &__opcoes {
    flex: 1;
    margin: 1rem 0;

    &:not(:last-of-type) {
      margin-right: 4rem;
    }

    &--desativado {
      .quartos__opcoes-dica {
        visibility: initial;
      }
      .quartos__opcao {
        color: var(--color-grey-dark-3);
        pointer-events: none;
        cursor: initial;
        &:hover {
          background-color: initial;
          color: var(--color-evento-2);
          border-color: var(--color-grey-light-3);
          .quartos__opcao-detalhe {
            color: var(--color-grey-dark-3);
          }
        }
      }
    }
  }

  &__opcoes-lista {
    display: flex;
    align-items: center;
  }

  &__opcoes-topo {
    min-height: 8rem;
    text-align: center;
  }

  &__opcoes-titulo {
    display: block;
  }

  &__opcoes-dica {
    color: var(--color-evento-1);
    display: block;
    font-size: 1.4rem;
    visibility: hidden;
  }

  &__opcao {
    flex: 1;
    margin: 1rem 1rem;
    padding: 1.5rem;
    border: 1px solid var(--color-grey-light-3);
    color: var(--color-grey-dark-4);
    //margin: 1rem 0;
    border-radius: 25px;
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    min-height: 8rem;

    &:focus,
    &:active {
      outline: none;
    }

    &:hover {
      border-color: transparent;
      background-color: var(--color-evento-2);
      color: #fff;
      .quartos__opcao-detalhe {
        color: #fff;
      }
    }

    &--selecionado {
      color: var(--color-evento-2);
      border-color: var(--color-evento-2);
    }
  }

  &__opcao-titulo,
  &__opcao-valor {
    font-weight: bold;
  }

  &__opcao-titulo {
    font-size: 1.8rem;
  }

  &__opcao-detalhe {
    font-size: 1.3rem;
    color: var(--color-grey-dark-3);
    font-weight: normal;
    display: block;
    text-align: center;
    &--uppercase {
      text-transform: uppercase;
    }
  }

  @include query(phone) {
    flex-direction: column;

    &__opcoes-topo {
      min-height: initial;
    }

    &__opcoes {
      margin: 1rem 0;
      &:not(:last-of-type) {
        margin-right: 0;
      }
    }
  }
}
