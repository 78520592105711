.pergunte {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .footer__creditos {
    margin-top: auto;
  }
}

.pergunte-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 4rem;
  &__topo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3rem;

    @include query(phone) {
      text-align: center;
    }
  }
}
.pergunte-logo {
  max-width: 20rem;
}

.criar-pergunta {
  width: 40rem;
  max-width: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.08), 0 3px 6px rgba(0, 0, 0, 0.16);
  padding: 2rem;
  border-radius: 3px;

  &__pergunta-feita {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.pergunta-form {
  text-align: center;
  &__input-wrapper {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0.5rem;
    flex: 1;
  }
  &__input {
    font-family: inherit;
    font-size: 17px;
    border: none;
    color: inherit;
    padding: 1.4rem 1.4rem;
    width: 100%;
    transition: all 0.2s;
    border: 1px solid var(--color-grey-light-3);
    border-radius: var(--border-radius);
    margin: 1rem 0;
    //margin-left: -3.25rem;

    &:focus {
      outline: none;
      //width: 110%;
      //background-color: var(--color-grey-light-3);
    }

    &::-webkit-input-placeholder {
      font-weight: 100;
      color: var(--color-grey-dark-3);
    }

    &--select {
      height: 5rem;
      background-color: #fff;
    }
  }

  &__botao {
    margin-top: 2rem;
  }

  &__carregando {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
