.local {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;

  @media(max-width: 900px) {
    flex-direction: column;
  }

  &__button {
    margin: 4rem 0;

    @media(max-width: 900px) {
      margin: 2rem 0;
    }
  }

  &__titulo {
    margin: 0;
    line-height: 1;
  }

  &__lista {
    margin-top: 2rem;
    padding-left: 2.5rem;
    list-style: inherit;

    li {
      font-size: 1.8rem;
      font-weight: bold;
    }
  }

  &__lista-splash {
    display: flex;
    flex-direction: column-reverse;
  }

  &__splash {
    // background-color: #478359;
    // border-radius: 50%;
    // width: 15rem;
    // height: 15rem;
    // padding: 2rem;
    // text-align: center;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    // font-size: 14px;
    // color: #fff;
    // border: 4px solid #A9D155;

    font-size: 1.8rem;
    background-color: #A9D155;
    padding: 0 5px;
    width: fit-content;
    text-align: center;

    @media(max-width: 390px) {
      width: fit-content;
      padding: 1rem;
    }
  }

  &__nome-hotel {
    margin-top: 2rem;
    line-height: 1;
    font-size: 3rem;
  }

  &__data {
    text-transform: uppercase;
    font-size: 2rem;
    color: gray;
  }

  &__paragrafo {
    font-size: 1.8rem;
    margin: 1.5rem 0;
  }

  &__fotos {
    display: flex;
    margin: 0 3.5rem 8rem 0;

    @media(max-width: 600px) {
      margin: 0 0 4rem 0;
    }
  }

  &__infos {
    max-width: 50rem;
  }

  &__fotos-grid-3 {
    display: grid;
    grid-template-areas: 
      "foto-0 foto-maior"
      "foto-1 foto-maior"
    ;
    grid-gap: 2rem;

    @media(max-width: 600px) {
      grid-template-areas: 
        "foto-0"
        "foto-1"
        "foto-maior"
      ;
    }
  }

  &__fotos-grid-4 {
    display: grid;
    grid-template-areas: 
      "foto-0 foto-maior"
      "foto-1 foto-maior"
      "foto-2 foto-maior"
    ;
    grid-gap: 2rem;

    @media(max-width: 600px) {
      grid-template-areas: 
        "foto-0"
        "foto-1"
        "foto-2"
        "foto-maior"
      ;
    }
  }

  &__foto-0{
    grid-area: foto-0;
  }

  &__foto-1{
    grid-area: foto-1;
  }

  &__foto-2{
    grid-area: foto-2;
  }

  &__foto-3{
    grid-area: foto-3;
  }

  &__fotos-grupo {
    display: flex;
    flex: 1 1;

    &--vertical {
      flex-direction: column;
    }

    &:not(:last-of-type) {
      margin-right: 4rem;
    }

    @include query(phone) {
      &:last-of-type {
        display: none;
      }
      &:not(:last-of-type) {
        margin: 2rem 0;
      }
    }
  }

  &__foto {
    object-fit: cover;
    border-radius: 20px;

    &--menor {
      height: 19rem;
      width: 30rem;

      @media(max-width: 900px) {
        height: auto;
        width: 100%;
      }

      @media(max-width: 600px) {
        height: auto;
        width: 100%;
      }
    }

    &--maior {
      height: 100%;
      width: 30rem;

      @media(max-width: 900px) {
        height: 100%;
        width: 100%;
      }

      @media(max-width: 600px) {
        height: auto;
        width: 100%;
      }

      grid-area: foto-maior;
    }

    &--vertical {
      max-height: 50rem;
    }

    &--horizontal {
      max-height: 25rem;
    }

    @include query(phone) {
      margin: 1rem auto;
    }
  }

  &__extras {
    font-size: 1.8rem;
  }

  &__extras-frase {
    margin-bottom: 3.5rem;
  }
  
  &__extras-hoteis {
    display: flex;

    @media(max-width: 550px) {
      flex-direction: column;
    }
  }

  &__extras-hotel {
    margin-right: 2rem;

    @media(max-width: 550px) {
      margin: 0 0 2rem 0;
    }

    h2 {
      font-weight: bold;
      color: var(--color-evento-2);
    }

  }
}
