.apresentacao {
  padding: 5rem 1rem;
  min-height: 73vh;
  display: flex;
  position: relative;

  @media(max-width: 500px) {
    min-height: 50vh;
  }

  &__menu-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    @media(max-width: 680px) {
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
  }

  &__infos-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-position: center center;
    object-fit: cover;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 3;   
    
    @media(max-width: 500px) {
      // justify-content: flex-end;
    }
  }

  &__titulo {
    margin: 2rem 0 0;
    font-size: 3.6rem;
    font-weight: 900;
    text-shadow: 2px 2px 7px #000;

    @include query(phone) {
      font-size: 3rem;
    }

    @media(max-width: 400px) {
      font-size: 2.1rem;
    }
  }

  &__subtitulo {
    font-size: 2.4rem;
    margin-top: 2rem;
    color: #fff;
    text-shadow: 2px 2px 4px #000;
    text-align: center;

    @media(max-width: 400px) {
      font-size: 1.6rem;
    }
  }

  &__extra {
    margin-top: 2rem;
    color: #fff;
    text-align: center;
    @include query(phone) {
      font-size: 1.5rem;
    }
  }

  &__participar {
    margin-top: 6rem;

    @media(max-width: 400px) {
      margin-top: 2rem;
    }
  }

  &__logo-evento {
    height: 28rem;
    width: auto;

    @media(max-width: 800px) {
      height: 15rem;
    }
  }

  &__detalhes {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    max-width: 100%;
    min-width: 70%;
    margin-top: 7rem;
    border-radius: 100px;
    background-color: transparentize($color: #000000, $amount: 0.4);
    padding: 1rem 3rem;

    @include query(tab-port) {
      flex-direction: column;
      padding: 1rem;
      border-radius: var(--border-radius);
      align-items: flex-start;
    }
  }

  &__detalhe {
    display: flex;
    align-items: center;
    color: var(--color-grey-light-4);
    margin: 1rem 2rem;
  }

  &__detalhe-icon-wrapper {
    margin-right: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__detalhe-icon {
    height: 3rem;
    width: 3rem;
    fill: var(--color-evento-1);
  }
}
