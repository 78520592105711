.footer {
  padding: 3rem 0 0;
  text-align: center;
  color: #fff;

  &__logo-marca {
    max-width: 20rem;
  }

  &__dados-legais {
    margin-top: 2rem;
    color: var(--color-grey-dark-2);
    font-size: 1.3rem;
  }

  &__creditos {
    background-color: #000;
    padding: 0.5rem;
    font-size: 1.5rem;
    text-align: center;
    color: var(--color-grey-dark-3);
    margin-top: 4rem;
    strong {
      color: #00bcd4;
    }
  }
}
