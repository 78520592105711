.menu {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  padding: 1rem 0;
  background-color: rgba(233, 212, 169, $alpha: .6);

  @media(max-width: 790px) {
    background-color: transparent;
  }

  @media(max-width: 500px) {
    display: none;
  }

  &__list {
    max-width: 75rem;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 790px) {
      justify-content: center;
    }
  }

  &__list-button {
    font-weight: 900;
    font-size: 1.4rem;
  }
  
  &__list-item {
    text-transform: uppercase;
    font-weight: 900;
    font-size: 1.8rem;
    cursor: pointer;

    @media(max-width: 790px) {
      display: none;
    }

    @media(max-width: 600px) {
      font-size: 1.6rem;
    }
    
    a {
      transition: all .3s;
      padding: 1rem;
      color: #6E4E25;
    }

    &:hover{
      a {
        background-color: #6E4E25;
        color: #fff;
      }
    }
  }
}