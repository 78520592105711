.atracao-background {
  background-image: url('https://eventosapi.p9.digital/storage/eventos/odontocompany/convencao-nacional-franqueados-2019/img/back-com.png');
  background-repeat: no-repeat;
  padding: 1rem 0 6rem 0;
  background-size: cover;
  background-position: center;

  @media(max-width: 1000px) {
    background-position: left;
  }
}

.atracao {
  &__conteudo {

    @media (max-width: 1087px) {
      padding: 0 2rem;
    }
  }  

  &__title {
    font-family: var(--font-programacao);
    color: #ece064;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 6rem;
    line-height: 1;
    width: 30rem;
    margin: 4rem 0;

    @media(max-width: 450px) {
      width: 100%;
      font-size: 5.3rem;
    }
  }

  &__texto {
    color: #fff;
    font-size: 2rem;
    width: 60rem;

    @media(max-width: 800px) {
      width: 100%;
    }
  }
}