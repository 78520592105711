.botao-2 {
  background-color: var(--color-evento-2);
  border-radius: var(--border-radius);
  border: none;
  box-shadow: var(--shadow-light);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-family: var(--font-primary);
  padding: 1.5rem 2.5rem;
  position: relative;
  transition: all 0.2s;

  &:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: var(--shadow-dark);
  }

  &:focus {
    transform: translateY(2px) scale(1);
    box-shadow: var(--shadow-dark-2);
  }

  &--marrrom {
    background-color: #6E4E25!important;
    background-image: none;
  }

  &--rounded {
    border-radius: 100px;
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--primary {
    background-image: linear-gradient(
      to bottom,
      var(--color-primary),
      var(--color-primary-dark)
    );
    color: #fff;
  }

  &--checkout {
    background-color: var(--color-green-checkout);
    margin-bottom: 3rem;
  }

  &:disabled {
    background-color: #ddd;
    &:hover {
      transform: none;
      box-shadow: var(--shadow-dark);
    }
  }

  &--pequeno {
    padding: 0.6rem;
  }

  &--sem-sombra {
    box-shadow: none;
    cursor: not-allowed;
    color: gray;
    
    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
}

.botao-3 {
  cursor: pointer;
  border-radius: var(--border-radius);
  transition: all 0.2s;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  margin: 0 1.5rem;
  &__icon {
    width: 2rem;
    height: 2rem;
    fill: var(--color-primary);
    margin-right: 1rem;
    transition: fill 0.2s;
    margin-top: -3px;
  }
  &--ativo {
    background-color: var(--color-primary);
    color: white;
    .botao-3__icon {
      fill: #fff;
    }
  }
  &:hover {
    background-color: var(--color-primary);
    color: white;
    box-shadow: var(--shadow-dark);
    transform: translateY(-3px);
    .botao-3__icon {
      fill: #fff;
    }
  }
}

.efeito-simples {
  transition: color 0.2s;
  cursor: pointer;
  &:hover {
    color: var(--color-primary);
  }
}

.botao-4 {
  background-color: #6E4E25;
  border-radius: var(--border-radius);
  border: none;
  box-shadow: var(--shadow-light);
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 1.6rem;
  font-family: var(--font-primary);
  padding: 1.5rem 2.5rem;
  position: relative;
  transition: all 0.2s;

  &:hover {
    transform: translateY(-3px) scale(1.02);
    box-shadow: var(--shadow-dark);
  }

  &:focus {
    transform: translateY(2px) scale(1);
    box-shadow: var(--shadow-dark-2);
  }

  &--rounded {
    border-radius: 100px;
  }

  &--uppercase {
    text-transform: uppercase;
  }
  
  &--sem-sombra {
    box-shadow: none;
    cursor: not-allowed;
    color: gray;
    
    &:hover {
      transform: none;
      box-shadow: none;
    }
  }
}