.texto-gradiente-1 {
  background-image: linear-gradient(
    to bottom,
    var(--color-secondary),
    var(--color-terciary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: inherit;
}

.titulo-1 {
  font-size: 2rem;
  font-family: var(--font-primary);
  margin: 1rem 0;

  &--branco {
    color: #fff;
  }
}

.titulo-2 {
  font-size: 2.5rem;
  font-family: var(--font-primary);
  margin: 1rem 0;
}

.titulo-3 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  margin: 1rem 0;
}

.titulo-4 {
  font-size: 2.5rem;
  color: var(--color-evento-2);
  margin: 1rem 0;

  &--pequeno {
    font-size: 1.9rem;
  }

  &--grande {
    font-size: 3rem;
  }
}

.secao-label {
  text-transform: uppercase;
  font-size: 1.5rem;
  color: var(--color-grey-light-4);
  position: relative;
  padding-left: 6rem;
  margin-top: 5rem;
  margin-bottom: 1rem;
  &:before {
    content: "";
    width: 4rem;
    height: 2px;
    background-color: var(--color-grey-light-4);
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.subtitulo-1 {
  font-size: 1.6rem;
}

.subtitulo-2 {
  font-size: 1.8rem;
  font-weight: bold;
  color: var(--color-grey-dark-4);

  &--uppercase {
    text-transform: uppercase;
  }
}

.paragrafo-1 {
  margin: 2rem 0;
}
