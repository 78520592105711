.palestrantes {
  padding-bottom: 4rem;

  &__titulo {
    width: 20%;
    margin-bottom: 4rem;
  }

  &__lista {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @include query(tab-port) {
      flex-wrap: wrap;
    }
  }

  &__segunda-lista {
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    @include query(tab-port) {
      flex-wrap: wrap;
    }
  }

  &__item {
    overflow: hidden;
    backface-visibility: hidden;
    flex: 1;
    margin: 0 2rem;
    position: relative;
    background-color: black;
    box-shadow: var(--shadow-light);
    background-color: #fff;
    border-radius: 3rem;

    &--menor {
      max-width: 17%;
      height: 28rem;
    }

    &--maior {
      max-width: 30%;
      height: 25rem;
    }

    @include query(tab-port) {
      min-width: 45%;
      height: 30rem;
      margin: 2rem auto;
      border-radius: var(--border-radius);
      &:first-of-type,
      &:last-of-type {
        border-radius: var(--border-radius);
      }
    }

    @media(max-width: 470px) {
      min-width: 80%;
      height: 30rem;
    }
  }

  &__nome {
    position: absolute;
    bottom: 2.5rem;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    text-transform: uppercase;
    color: #fff;
    z-index: 3;
    font-weight: bold;
    text-align: center;

    // &:before {
    //   content: "";
    //   position: absolute;
    //   height: 2px;
    //   width: 80%;
    //   top: -2rem;
    //   background-color: var(--color-evento-2);
    //   left: 50%;
    //   transform: translateX(-50%);
    //   text-align: center;
    // }

    // @include query(phone) {
    //   &:before {
    //     top: -1rem;
    //   }
    // }
  }

  &__sombra {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 70%;
    width: 100%;

    background-image: linear-gradient(to top, #0000008f, transparent);
    height: 110%;
    transition: all 0.2s;
    &--menor {
      background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 1),
        transparent 70%,
        transparent
      );
    }
  }

  &__cargo {
    text-align: center;
    display: block;
    text-transform: initial;
  }

  &__foto {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: all 0.2s;
    &--cover {
      object-fit: cover;
    }
  }

  @include query(phone) {
    padding-bottom: 2rem;
  }
}

.palestrantes__item {
  &:hover {
    .palestrantes__foto {
      width: 110%;
      height: 110%;
    }
    .palestrantes__sombra {
      background-image: linear-gradient(to top, rgb(169, 209, 85), transparent);
    }
  }
}

.palestrantes-background {
  background-image: url('https://eventosapi.p9.digital/storage/eventos/odontocompany/convencao-nacional-franqueados-2019/img/lista-2-palestrantes.png');
  background-repeat: no-repeat;
  background-position-y: 50rem;
}