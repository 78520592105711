.comprar-header {
  color: var(--color-grey-dark-4);
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__titulo {
    font-size: 2.5rem;
    font-weight: bold;
  }

  &__logo-marca {
    max-width: 15rem;
  }

  &__detalhes {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  &__detalhe {
    display: flex;
    align-items: center;
    color: var(--color-grey-dark-3);
    margin-right: 1rem;
  }

  &__detalhe-icon-wrapper {
    margin-right: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__detalhe-icon {
    height: 2.5rem;
    width: 2.5rem;
    fill: var(--color-grey-dark-3);
  }

  @include query(phone) {
    &__container {
      flex-direction: column;
    }
    &__evento {
      order: 1;
      margin-top: 2rem;
    }
  }
}
