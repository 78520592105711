.comprar {
  background-color: #fff;

  &__encerrado {
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
    font-weight: normal;
    margin: 4rem 0 3.5rem;
  }

  &__encerrado-tel {
    font-weight: bold;
    color: #fff;
  }

  &__subtotal {
    color: #fff;
  }
  &__subtotal-texto {
    color: #fff;
    @include query(phone) {
      margin-left: 2rem;
    }
  }
  &__subtotal-valor {
    margin-left: 1rem;
    font-weight: bold;
  }
}

.comprar-form {
  &__investimento {
    text-align: center;
    font-size: 1.8rem;
  }
  &__valor {
    color: var(--color-evento-2);
  }
  &__input-wrapper {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0;
  }
  &__input {
    font-family: inherit;
    font-size: 19px;
    border: none;
    color: inherit;
    padding: 1.2rem 2rem;
    padding-left: 4rem;
    width: 70%;
    transition: all 0.2s;
    border-radius: var(--border-radius);
    background-color: var(--color-grey-light-3);
    margin: 1rem 0;
    //margin-left: -3.25rem;

    &:focus {
      outline: none;
      width: 80%;
      //background-color: var(--color-grey-light-3);
    }

    &::-webkit-input-placeholder {
      font-weight: 100;
      color: var(--color-grey-dark-1);
    }
  }

  &__submit-wrapper {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
  }
  &__submit {
    margin: 0 auto;
  }
}

.progresso {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 2rem 0;
  position: relative;

  &__item-nome{
    color: var(--color-evento-2);
  }

  &:after {
    content: "";
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 1.1rem;
    background-color: var(--color-evento-2);
    width: 65%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.8;
  }

  @include query(phone) {
    margin: 0 0;
  }

  &__limpar {
    text-align: center;
    font-size: 1.3rem;
    display: block;
    margin-top: 1rem;
    cursor: pointer;
  }

  &__item {
    display: flex;
    flex-direction: column;
    color: var(--color-white-transparency);
    align-items: center;

    &--ativo {
      color: #fff;
      font-weight: bold;
      .progresso__item-circulo:after {
        content: "";
        width: 1.5rem;
        height: 1.5rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: var(--color-evento-2);
        border-radius: 50%;
      }
    }

    @include query(phone) {
      font-size: 1.4rem;
    }

    @include query(phone-tiny) {
      font-size: 1.2rem;
    }
  }

  &__item-check {
    display: none;
    width: 2.3rem;
    height: 2.3rem;
    fill: var(--color-grey-dark-2);
  }

  &__item-circulo {
    margin-top: 2rem;
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    z-index: 2;
    
    &--borda-home {
      border: 2px solid var(--color-evento-2);
    }
  }

  &__total {
    margin-top: 3rem;
    border-top: 1px solid var(--color-grey-light-4);
    padding-top: 1rem;
    font-size: 1.5rem;

    color: var(--color-grey-dark-4);
  }

  &__total-item {
    display: flex;
    justify-content: space-between;
    &--total {
      font-weight: bold;
    }
  }

  &__total-valor {
    margin-left: 3rem;
  }
}

//separando aqui para ficar mais organizado -- comprar tem esse modificador quando esta em modo full na pagina de checkout
.comprar--completo {
  background-color: #fff;
  //min-height: 200vh;
  padding: 2rem 0.5rem;
  .comprar {
    &__container {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      @include query(phone) {
        flex-direction: column;
      }
    }

    &__encerrado {
      display: none;
    }

    &__conteudo-wrapper {
      flex: 1 1;
      max-width: 80rem;
    }

    &__progresso-wrapper {
      flex: 0 0 20%;
      max-width: 40rem;
      position: sticky;
      padding-top: 10rem;
      top: 0;
      order: 1;
    }

    &__subtotal {
      color: var(--color-grey-dark-3);
    }
    &__subtotal-texto {
      color: var(--color-grey-dark-3);
      @include query(phone) {
        margin-left: 2rem;
      }
    }
  }

  .progresso {
    flex-direction: column;
    align-items: flex-start;
    &::after {
      visibility: hidden;
    }
    &__item {
      color: var(--color-grey-dark-4);
      flex-direction: row;
      align-items: flex-start;
      margin: 0.3rem 0;
      .progresso__item-check {
        display: initial;
      }
      &--completo {
        .progresso__item-check {
          fill: var(--color-green-checkout);
        }
      }
    }
    &__item-circulo {
      margin: 0;
      margin-right: 1rem;
      background-color: #fff;
      &:after {
        visibility: hidden;
      }
    }
    &__item-nome {
      order: 1;
    }

    @include query(phone) {
      display: none;
    }
  }
}

.modal-tentando {
  &__texto {
    color: #fff;
    font-size: 1.6rem;
    margin-top: 2rem;
    text-transform: uppercase;
    color: #fff;
  }

  .modal-content {
    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;
  }
}
