.programacao {
  display: flex;

  @media(max-width: 960px) {
    flex-direction: column;
  }
}

.programacao-dia {
  width: 410px;
  display: flex;
  padding: 6rem 0;
  
  @media(max-width: 960px) {
    flex-direction: column;
    align-items: center;
    padding: 4rem 0;
  }

  @media(max-width: 960px) {
    width: 100%;
  }

  &__dropdown {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    height: 0px;
    transition: all .3s;
    padding: 2rem 0;
    display: none;
    height: 600px;

    &--ativo {
      display: flex;
    }

    &--0{
      background-color: var(--color-evento-2);
      align-items: flex-end;
      justify-content: flex-start;
    }
  
    &--1{
      background-color: var(--color-evento-1);
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 4rem;
    }

    @media(max-width: 960px) {
      align-items: center;
      padding: 0;
    }
  }

  &__dropdown-lista {
      width: 410px;
      margin-left: 1rem;
      transition: all .3s;
      
      li{
        height: 0;
        transition: all .3s;
        color: #fff;
        text-transform: uppercase;
      }

      &--ativo {
        li{
          height: 25px;
          color: #000;

          @media(max-width: 960px) {
            height: fit-content;
          }
        }
      }

      @media(max-width: 960px) {
        width: fit-content;
      }
  }

  &__dropdown-item {
    display: flex;
    // justify-content: center;
    align-items: center;
    margin-bottom: 1rem;

    p {
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      margin-left: 1rem;

      @media (max-width: 600px) {
        text-transform: inherit;
      }
     }

    &__icon {
      img {
        height: 30px;
        width: 30px;
        object-fit: cover;
      }

      span {
        height: 30px;
        width: 30px;
        background-color: #fff;
        border-radius: 50%;
      }

      display: flex;
      padding: 1rem;
      border-radius: 50%;
      border: 0.3rem solid #fff;
      
    }

    &__foto {
      img {
        height: 55px;
        width: 55px;
        object-fit: cover;
        border-radius: 50%;
        border: 0.3rem solid #fff;
      } 
    }
  }
  
  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    &--0{
      background-color: var(--color-evento-2);
      align-items: flex-end;
      padding-right: 6rem;
    }
  
    &--1{
      background-color: var(--color-evento-1);
      align-items: flex-start;
      padding-left: 4rem;
    }

    @media(max-width: 1000px) {
      padding: 0;
      align-items: center;
    }

  }

  &__data {
    color: #fff;
    font-size: 8rem;
    font-weight: bold;
    text-transform: uppercase;
    font-family: var(--font-programacao);
    line-height: 1;

    @media(max-width: 960px) {
      text-align: center;
    }
  }

  &__mes {
    color: #fff;
    font-size: 4rem;
    font-weight: bold;
    text-transform: uppercase;
    font-family: var(--font-programacao);
    line-height: 1;

    @media(max-width: 960px) {
      text-align: center;
      margin-bottom: 1.5rem;
    }
  }

  &__infos{
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 3rem;

    @media(max-width: 960px) {
      justify-content: center;
      align-items: center;
      margin-left: 0;
    }

    p{
      text-transform: uppercase;
    }

    span{
      color: #fff;
      font-style: italic;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}