.lista-perguntas {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  &__carregando {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .botao-2 {
      background-color: #ef5350;
      margin-left: 1rem;
    }
  }

  .footer__creditos {
    align-self: stretch;
    margin-top: auto;
  }
}

.perguntas-table {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 4rem;

  &__row {
    cursor: pointer;
  }
}

.pergunta-conteudo {
  &__linha {
    margin: 0.5rem;
  }
}

//estilo animacao lista
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
