.dados-pagamento {
  padding: 2rem 0;

  &__form-titulo {
    display: block;
    margin: 1rem 0;
    font-size: 2rem;
    text-align: center;
    font-weight: bold;
  }
  &__forms {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 3rem;
  }
  &__cartao {
    flex: 0 0 60%;
    margin-right: 1rem;
    padding: 1rem 2rem;
    border-radius: var(--border-radius);
    border: 1px solid var(--color-grey-light-3);
    position: relative;
  }
  &__titulo {
    flex: 1;
  }
  &__inputs {
    margin: 2rem 0;
  }
  &__input-row {
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    &--multiple {
      .dados-pessoais__input-wrapper {
        margin: 0.5rem;
      }
    }
  }
  &__input-wrapper {
    display: flex;
    justify-content: center;
    margin: 0.5rem 0.5rem;
    flex: 1;
    &--cvc {
      max-width: 12rem;
    }
  }

  &__observacao {
    margin: 1rem 0;
  }

  &__meios {
    margin: 2rem 0;
    @include query(phone) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__meio-button {
    background-color: #fff;
    color: var(--color-evento-1);
    border: 1px solid var(--color-evento-1);
    &:not(:last-of-type) {
      margin-right: 2rem;
    }
    &--ativo {
      background-color: var(--color-evento-1);
      color: #fff;
    }
    @include query(phone) {
      margin: 0 1rem;
    }
  }

  &__input {
    font-family: inherit;
    font-size: 17px;
    border: none;
    color: inherit;
    padding: 2rem 2rem;
    width: 100%;
    transition: all 0.2s;
    border: 1px solid var(--color-grey-light-3);
    border-radius: var(--border-radius);
    margin: 1rem 0;
    //margin-left: -3.25rem;

    &:focus {
      outline: none;
      //width: 110%;
      //background-color: var(--color-grey-light-3);
    }

    &::-webkit-input-placeholder {
      font-weight: 100;
      color: var(--color-grey-dark-3);
    }

    &--select {
      height: 6rem;
      background-color: #fff;
    }
  }

  &__finalizar {
    width: 100%;
    margin: 4rem 0;
    background-color: var(--color-green-checkout);
    border-radius: var(--border-radius);
  }

  &__texto-legal-wrapper {
    margin-top: 3rem;
  }

  &__texto-legal {
    color: var(--color-grey-dark-3);
    font-size: 1.5rem;
  }

  &__brand-wrapper {
    position: absolute;
    left: 50%;
    top: 0rem;
    display: flex;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid var(--color-grey-light-3);
    height: 7rem;
    align-items: center;
    width: 7rem;
    transform: translate(-50%, -50%);
    overflow: hidden;
  }

  &__total {
    display: none;
  }

  @include query(phone) {
    flex-direction: column;
    align-items: stretch;
    &__cartao,
    &__titular {
      flex: 1;
      width: 100%;
    }
    &__forms {
      flex-direction: column;
    }
    &__total {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}

.erro-pagamento {
  &__texto {
    color: orangered;
    font-size: 1.8rem;
    padding: 1rem;
    margin: 1rem 0;
    text-align: center;
  }
}
