.ingressos {
  display: flex;
  flex-direction: column;

  &__continuar {
    margin-top: 2rem;
    align-self: flex-end;
    @include query(phone) {
      align-self: center;
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  &__expandir {
    display: flex;
    align-items: center;

    &--expandend {
      .ingressos__expandir-icone {
        transform: rotate(-90deg);
      }
    }
  }

  &__expandir-texto {
    color: var(--color-grey-dark-3);
  }

  &__expandir-icone {
    width: 4rem;
    height: 4rem;
    fill: var(--color-grey-dark-3);
    transform: rotate(90deg);
  }

  &--completo {
    margin-top: 3rem;
    .ingresso {
      margin: 1rem 0;
      border-bottom: 1px solid var(--color-grey-light-3);
      box-shadow: none;
    }
  }

  &--collapsed {
    .ingresso {
      display: none;
    }
  }

  @include query(phone) {
    margin-top: 2rem;
  }
}

.ingresso {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
  padding: 2rem 3rem;
  border-radius: 15px;
  background: linear-gradient(
    to right,
    var(--color-evento-2),
    var(--color-evento-1)
  );
  box-shadow: var(--shadow-very-light);

  &__titulo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;

    @include query(phone) {
      width: 100%;
      align-items: center;
      margin-bottom: 1rem;
    }
  }

  &__nome {
    font-size: 2rem;

    @include query(phone) {
      font-size: 1.8rem;
    }
  }

  &__observacao {
    color: #fff;
    max-width: 20rem;
    font-size: 1.6rem;
    text-align: center;

    @media (max-width: 600px) {
      margin: 1.5rem 0;
    }
  }

  &__data {
    color: #fff;
  }

  &__valor {
    font-size: 1.8rem;
    text-align: center;
    color: #fff;
    font-weight: bold;

    @include query(phone) {
      margin: 0;
    }
  }

  &__valor-observacao {
    font-size: 1.6rem;
    display: block;
    color: var(--color-grey-dark-3);
  }

  &__adicionar {
    display: flex;
    align-items: center;
    overflow-x: hidden;
    cursor: pointer;
  }

  &__adicionar-texto {
    //transform: translateX(300%);
    transition: all 0.5s;
    display: inline-block;
    font-size: 1.7rem;
    margin: 0 0.5rem;
    color: white;
    font-weight: bold;

    @include query(phone) {
      transform: translateX(0);
      font-size: 1.5rem;
    }
  }

  &__adicionar-icone {
    width: 3rem;
    height: 3rem;
    fill: white;
    transition: all 0.5s;
  }

  &__adicionado-icone {
    width: 3rem;
    height: 3rem;
    fill: white;
    transition: all 0.5s;
  }

  &__remover-icone {
    width: 3rem;
    height: 3rem;
    fill: white;
    transition: all 0.5s;
    transform: translateX(300%);

    @include query(phone) {
      transform: translateX(0);
    }
  }

  @include query(phone) {
    padding: 2rem;
    margin: 1rem;
    flex-wrap: wrap;

    align-items: center;
    flex-direction: column;
  }

  &--indisponivel {
    .ingresso {
      &__adicionar {
        cursor: initial;
        pointer-events: none;
      }
    }
  }
}

/*
.ingresso__adicionar:hover {
  .ingresso__adicionar-icone {
    transform: rotate(180deg);
  }
  .ingresso__adicionar-texto {
    transform: translateX(0);
  }
}

.ingresso__adicionar--adicionado {
  .ingresso__adicionar-texto {
    transform: translateX(0);
  }

  &:hover {
    .ingresso__adicionar-icone {
      transform: rotate(0deg);
    }
    .ingresso__remover-icone {
      transform: translateX(0);
    }
  }
}
*/
