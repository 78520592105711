:root {
  //cores
  --font-primary: 'Lato';
  --font-programacao: 'Oswald';

  --color-evento-1: #faf9f9;
  --color-evento-2: #ccc;
  --color-evento-3: #999;

  --color-primary: #b01117;
  --color-primary-dark: #b01117;

  --color-secondary: #97fb87;

  --color-terciary: #00fdb7;

  --color-grey-light-1: #faf9f9;
  --color-grey-light-2: #f4f2f2;
  --color-grey-light-3: #f0eeee;
  --color-grey-light-4: #ccc;

  --color-grey-very-light-1: #cccccc75;

  --color-grey-dark-1: #282828;
  --color-grey-dark-2: #bcbec0;
  --color-grey-dark-3: #999;
  --color-grey-dark-4: #505050;

  --color-white-transparency: #ffffff96;

  --color-green-checkout: #74c108;

  //init de cores de vento
  --color-evento-1: #282828;
  --color-evento-2: #bcbec0;
  --color-evento-3: #999;
  --color-evento-4: #505050;

  //sombras
  --shadow-very-light: 0 1px 5px rgba(100, 100, 100, 0.1);

  --shadow-light: 0 1rem 4rem rgba(0, 0, 0, 0.2);

  --shadow-dark: 0 2rem 6rem rgba(0, 0, 0, 0.3);
  --shadow-dark-2: 0 1rem 5rem rgba(0, 0, 0, 0.3);

  --shadow-color-primary: 0 0 20px rgba(204, 217, 253, 0.5);

  //outros
  --border-radius: 10px;

  //tamanhos das cells da tabela de programacao
  //deixando aqui no root para nao precisar selecionar o node da tabela
  --programacao-colspan-1: 25%;
  --programacao-colspan-2: 50%;
}

//mixins
@mixin query($breakpoint) {
  @if $breakpoint==phone-tiny {
    @media only screen and (max-width: 22em) {
      @content;
    } //352px
  }
  @if $breakpoint==phone-small {
    @media only screen and (max-width: 25em) {
      @content;
    } //400px
  } @else if $breakpoint==phone {
    @media only screen and (max-width: 37.5em) {
      @content;
    } //600px
  } @else if $breakpoint==tab-port {
    @media only screen and (max-width: 56.25em) {
      @content;
    } //900px
  } @else if $breakpoint==tab-land {
    @media only screen and (max-width: 76.25em) {
      @content;
    } //1220px
  } @else if $breakpoint==desk-small {
    @media only screen and (max-width: 90em) {
      @content;
    } //1440px
  } @else if $breakpoint==desk-med {
    @media only screen and (max-width: 112.5em) {
      @content;
    } //1800px
  } @else if $breakpoint==desk-large {
    @media only screen and (min-width: 1801px) {
      @content;
    } //1801px - min
  } @else {
    @media only screen and (max-width: $breakpoint) {
      @content;
    } //customizado
  }
}

//largura minima
@mixin query-w-min($pixels) {
  @media only screen and (min-width: $pixels) {
    @content;
  }
}

//altura
@mixin query-h($pixels) {
  @media only screen and (max-height: $pixels) {
    @content;
  }
}
